<template>
  <div>
    <div v-if="isSignedInCuraytor && (domain_list.length < 1)">
      <b-spinner variant="danger" type="grow" label="Spinning" class="spinner initial-load"></b-spinner>
    </div>
    <div class="ConnectChime" v-if="isSignedInCuraytor && (domain_list.length > 0)">
        <b-button v-b-toggle.conn-chime-cont variant="primary" class="collapse-gen" @click.prevent="initSitesTA()">
          <h3 class="section-title"> Connect Chime</h3></b-button>
        <b-collapse id="conn-chime-cont" class="mt-2">
          <div id="flex-container">
            <div class="flex-item" id="sites-select-conn">
              <h3 class="sub-title" >Select a Site:</h3>
              <input class="typeahead sites-TA-conn" type="text" placeholder=" Select a site">
            </div>
            <div class="flex-item">
              <h3 class="sub-title">Enter Chime Email: </h3>
              <input v-model="chime_email" input type="email" placeholder=" Enter Chime Email">
            </div>
            <div class="flex-item">
              <h3 class="sub-title" >Enter Chime API Key: </h3>
              <input v-model="chime_api_key" type="text" placeholder=" Enter Chime API Key">
            </div>
          </div>
          <button @click.prevent="initConnection()" class="submit-button">Initialize Connection</button>
          <div class="spinner-box">
            <b-spinner v-if="isLoading" variant="primary" label="Spinning" class="spinner isLoading"></b-spinner>
          </div>
          <div v-if="showError">
            <p class="error-text">{{ errorMsg }}</p>
          </div>
          <div v-if="showSuccess">
            <p class="success-text">{{ successMsg }}</p>
          </div>
      </b-collapse>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import axios from 'axios'
import $ from 'jquery'
import 'typeahead.js'
let Bloodhound = require("typeahead.js/dist/bloodhound.min.js")
const https = require('https')

export default {
  name: 'ConnectChime',
  mounted() {
  },
  props: {
    token_object: Object,
    domain_list: Array,
    isSignedInCuraytor: Boolean
  },
  data() {
    return {
      showWarning: false,
      appsList: null,
      domain: '',
      chime_email: '',
      chime_api_key: '',
      isLoading: false,
      showError: false,
      showSuccess: false,
      errorMsg: '',
      successMsg: 'Success!'
    };
  },
  watch: {
    token_object: function () {
      this.isSignedInCuraytor = true;
    },
    domain_list: function () {
      this.initSitesTA();
    }
  },
  methods: { 
    initConnection() {
      this.isLoading = true;
      let isDataValid = this.validateData();
      if (isDataValid) {
        this.connectChime();
      }
    },
    validateData() {
      if (this.domain && this.chime_email && this.chime_api_key) {
        this.errorMsg = '';
        this.showError = false;
        return true
      }
      else {
        this.isLoading = false;
        this.errorMsg = "All fields are required"
        this.showError = true;
        return false
      }
    },
    connectChime() {
      var data = {
        "domain": this.domain,
        "chime_email": this.chime_email,
        "chime_api_key": this.chime_api_key
      }
      axios.post(`${process.env.VUE_APP_BASE_URL}/crm/settings`, data, {
        headers: {
          Authorization: this.token_object.crm_sync_set_pref_token,
          "Content-Type": "application/json"
        }
      })
      .then(res => {
        console.log('res', res)
        let data = {
          "domain": this.domain
        }
        if (res.status > 199 && res.status < 300) {
          axios.post(`${process.env.VUE_APP_BASE_URL}/crm/sync`,data,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: this.token_object.crm_sync_set_pref_token,
            }
          })
          .then(res => {
            if (res.message == "Endpoint request timed out") {
              this.showSuccess = true;
              this.isLoading = false;
            }
            if (res.status > 199 && res.status < 300){
              this.showSuccess = true;
              this.isLoading = false;
            }
          })
          .catch(error => {
            this.isLoading = false;
            if (error.message == "Network Error") {
              this.showSuccess = true;
            }
            else {
              this.errorMsg = error;
              this.showError = true;
            }
          })
        } 
      })
      .catch(err => {
        let wrongPassword = err.response.data.errorMessage.includes('account or password is incorrect');
        if (wrongPassword) {
          this.errorMsg = 'Your email or password is incorrect';
          this.isLoading = false;
          this.showError = true;
        }
        else {
          this.errorMsg = err;
          this.isLoading = false;
          this.showError = true;
        }
      })
    },
    initSitesTA() {
      $('.sites-TA-conn').typeahead('destroy');
      let domains = new Bloodhound({
         datumTokenizer: function(d) {
          return Bloodhound.tokenizers.whitespace(d.domain);
        },
        identify: function(obj) { return obj.domain; },
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        local: this.domain_list,
        limit: 10
      });

      function domainsWithDefaults(q, sync) {
        if (q === '') {
          sync(domains.all()); 
        }
        else {
          domains.search(q, sync);
        }
      }
      $('#sites-select-conn .typeahead').typeahead({
        hint: false,
        highlight: true,
        minLength: 0
      },
      {
        name: 'domains',
        displayKey: 'domain',
        source: domainsWithDefaults,
        limit: 10
      })
      $('#sites-select-conn').on('typeahead:selected', (e, datum) => {
        this.selectedSite = datum
        this.domain = this.selectedSite.domain;
      });
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.initial-load {
  color: red;
}

</style>
