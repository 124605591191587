<template>
  <div id="app">
    <img id="curaytor-logo" src="./assets/big-c.png">
    <h1 id="cur-title">Curaytor</h1>
    <h2 id="chime-title">Internal Chime Platform</h2>
    <Login msg="Login Component" @childSyncInfo="syncUserInfo" @syncSignedIn="syncSignedIn"/>
    <ConnectChime :token_object="token_object" :domain_list="domain_list" :isSignedInCuraytor="isSignedInCuraytor"/>
    <SyncLeads :token_object="token_object" :domain_list="domain_list" :isSignedInCuraytor="isSignedInCuraytor"/>
    <SwitchCRM :token_object="token_object" :domain_list="domain_list" :isSignedInCuraytor="isSignedInCuraytor"/>


  </div>
</template>

<script>
import Login from './components/Login.vue'
import ConnectChime from './components/ConnectChime.vue'
import SyncLeads from './components/SyncLeads.vue'
import SwitchCRM from './components/SwitchCRM.vue'
import axios from 'axios'


export default {
  name: 'App',
  components: {
    Login,
    SyncLeads,
    ConnectChime,
    SwitchCRM
  },
  data() {
    return {
      auth_api_token: null,
      token_object: null,
      domain_list: [],
      isSignedInCuraytor: null
    };
  },
  watch: {
    token_object: function () {
      this.getSites();
    }
  },
  methods: {
    syncUserInfo (tokenObj) {
     this.token_object = tokenObj;
    },
    syncSignedIn(isSignedInCuraytor) {
      this.isSignedInCuraytor = isSignedInCuraytor;
    },
    getSites() {
      axios.get('https://auth.curaytor.net/sites', {
        headers: {
          Authorization: this.token_object.auth_api_token,
          domain: 'curaytor.com'
        }
      })
      .then(res => {
        let domainsWithUsers = [];
        let domains = res.data.data.map(site => {
          if (site.users !== undefined) {
            domainsWithUsers.push(site)
          }
        })
        let domainList = domainsWithUsers.map(record => {
            return { domain: record.domain, site_id: record.site_id } 
        })        
        this.domain_list = domainList;
      })
    },
  }
}
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

#cur-title {
  font-size: 1.5em;
  font-weight: 800;
  margin-bottom: .7em;
}

h3 {
  margin: .2em;
}

#curaytor-logo {
  height: 5em;
  margin: 1em;
}

#chime-title {
  font-size: 2em;
  font-weight: 600;
  margin: 1em;
}

</style>
