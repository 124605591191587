<template>
  <div class="SyncLeads" v-if="isSignedInCuraytor && (domain_list.length > 0)">
    <b-button v-b-toggle.sync-leads-cont class="collapse-gen" variant="primary" @click.prevent="initSitesTA()"><h3 class="section-title"> Synchronize leads</h3>
    </b-button>
      <b-collapse id="sync-leads-cont" class="mt-2">
      <div id="flex-container">
        <div class="flex-item" id="sites-select-sync">
          <h3 class="sub-title">Select a Site:</h3>
          <input class="typeahead sites-TA-sync" type="text" placeholder="Select a site">
        </div>
      </div>
      <button @click.prevent="initSync()" class="submit-button">Initialize Sync</button>
      <div class="spinner-box">
        <b-spinner v-if="isLoading" variant="primary" label="Spinning" class="spinner isLoading"></b-spinner>
      </div>
      <div v-if="showError">
        <p class="error-text">{{ errorMsg }}</p>
      </div>
      <div v-if="showSuccess">
        <p class="success-text">{{ successMsg }}</p>
      </div>
    </b-collapse>  
  </div>
</template>

<script>
import Vue from 'vue'
import axios from 'axios'
import $ from 'jquery'
import 'typeahead.js'
let Bloodhound = require("typeahead.js/dist/bloodhound.min.js")
const https = require('https')

export default {
  name: 'SyncLeads',
  mounted() {
  },
  props: {
    token_object: Object,
    domain_list: Array,
    isSignedInCuraytor: Boolean
  },
  data() {
    return {
      domain: '',
      isLoading: false,
      showError: false,
      showSuccess: false,
      successMsg: 'Success!',
      errorMsg: '',
    };
  },
  watch: {
    token_object: function () {
      this.initSitesTA();
      this.isSignedInCuraytor = true;
    }
  },
  methods: { 
    initSync() {
      this.isLoading = true;
      if (this.domain) {
        this.syncLeads();
      }
      else {
        this.isLoading = false;
        this.errorMsg = "All fields are required"
        this.showError = true;
      }
    },
    syncLeads() {
      var domain = { "domain": this.domain }
      axios.post(`${process.env.VUE_APP_BASE_URL}/crm/sync`, domain, {
        headers: {
          Authorization: this.token_object.crm_sync_set_pref_token,
          "Content-Type": "application/json"
        }
      })
      .then(res => {
        if (res.message == "Endpoint request timed out") {
          this.showSuccess = true;
          this.isLoading = false;
        }
        if (res.status > 199 && res.status < 300){
          this.showSuccess = true;
          this.isLoading = false;
        }
      })
      .catch(error => {
        this.isLoading = false;
        if (error.message == "Network Error") {
          this.showSuccess = true;
        }
        else {
          this.errorMsg = error;
          this.showError = true;
        }
      })
    },
    initSitesTA() {
      $('.sites-TA-sync').typeahead('destroy');
      let domains = new Bloodhound({
         datumTokenizer: function(d) {
          return Bloodhound.tokenizers.whitespace(d.domain);
        },
        identify: function(obj) { return obj.domain; },
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        local: this.domain_list,
        limit: 10
      });

      function domainsWithDefaults(q, sync) {
        if (q === '') {
          sync(domains.all()); 
        }
        else {
          domains.search(q, sync);
        }
      }
      $('#sites-select-sync .typeahead').typeahead({
        hint: false,
        highlight: true,
        minLength: 0
      },
      {
        name: 'domains',
        displayKey: 'domain',
        source: domainsWithDefaults,
        limit: 10
      })
      $('#sites-select-sync').on('typeahead:selected', (e, datum) => {
        this.selectedSite = datum
        this.domain = this.selectedSite.domain;
      });
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>



</style>
