<template>
  <div class="SwitchCRM" v-if="isSignedInCuraytor && (domain_list.length > 0)">
    <b-button v-b-toggle.switch-crm-cont class="collapse-gen" variant="primary" @click.prevent="initSitesTA()">
      <h3 class="section-title"> Switch CRM </h3>
    </b-button>
      <b-collapse id="switch-crm-cont" class="mt-2">
        <div id="reminder-cont">
          <p id="reminder"> After switching CRM, the users of the account need to Connect to Convert again in Manage App (Choosing a Match CRM User → Open Invitation Email → Authorize)</p>
        </div>
      <div id="flex-container">
        <div class="flex-item" id="sites-select-switch">
          <h3 class="sub-title">Select a Site:</h3>
          <input class="typeahead sites-TA-sync" type="text" placeholder="Select a site">
        </div>
        <div>
          <h3 class="sub-title" >Select a CRM:</h3>
          <b-form-select v-model="selectedCRM" :options="crmOptions"></b-form-select>
        </div>
      </div>
      <button @click.prevent="initSwitch()" class="submit-button">Switch CRM</button>
      <div class="spinner-box">
        <b-spinner v-if="isLoading" variant="primary" label="Spinning" class="spinner isLoading"></b-spinner>
      </div>
      <div v-if="showError">
        <p class="error-text">{{ errorMsg }}</p>
      </div>
      <div v-if="showSuccess">
        <p class="success-text">{{ successMsg }}</p>
      </div>
    </b-collapse>  
  </div>
</template>

<script>
import Vue from 'vue'
import axios from 'axios'
import $ from 'jquery'
import 'typeahead.js'
let Bloodhound = require("typeahead.js/dist/bloodhound.min.js")
const https = require('https')

export default {
  name: 'SwitchCRM',
  mounted() {
  },
  props: {
    token_object: Object,
    domain_list: Array,
    isSignedInCuraytor: Boolean
  },
  data() {
    return {
      domain: '',
      site_id: null,
      isLoading: false,
      showError: false,
      showSuccess: false,
      successMsg: 'Success!',
      errorMsg: '',
      selectedCRM: null,
      crmOptions: ['Chime', 'FUB']
    };
  },
  watch: {
    domain_list: function () {
      this.initSitesTA();
    }
  },
  methods: { 
    initSwitch() {
      this.isLoading = true;
      let isDataValid = this.validateData();
      if (isDataValid) {
        this.updateCRM();
      }
    },
    validateData() {
      if (this.domain && this.selectedCRM && (this.selectedCRM.toLowerCase().includes('fub') || this.selectedCRM.toLowerCase().includes('chime'))) {
        this.errorMsg = '';
        this.showError = false;
        return true
      }
      else {
        this.isLoading = false;
        this.errorMsg = "All fields are required"
        this.showError = true;
        return false
      }
    },
    removeFubKeySitesDb() {
      let data = {
        "settings": {
          "fub_api_key": ""
        }
      }
      axios.put(`https://api.curaytor.io/api/v1/sites/${this.site_id}`, data, {
        headers: {
          Authorization: `Bearer ${this.token_object.auth_api_token}`,
          "domain": this.domain
        }
      })
      .then(res => {
        if ((res.status > 199 && res.status < 300)) {
          this.isLoading = false;
          this.showSuccess = true;
        }
      })
      .catch(error => {
        console.log(error)
        this.errorMsg = error;
        this.showError = true;
      })
    },
    resetUsersCrmSitesDb() {
      axios.get(`https://api.curaytor.io/api/v1/sites/${this.site_id}/crm_reset`, {
        headers: {
          Authorization: `${this.token_object.auth_api_token}`,
          "domain": this.domain
        }
      })
      .then(res => {
        if ((res.status > 199 && res.status < 300)) {
          if (this.selectedCRM.toLowerCase() == "chime") {
            this.removeFubKeySitesDb();
          }
          else {
            this.isLoading = false;
            this.showSuccess = true;
          }
        }
      })
      .catch(error => {
        console.log(error)
        this.errorMsg = error;
        this.showError = true;
      })
    },
    resetLeadDataAlgolia() {
      var data = {
        "domain": this.domain
      }
      axios.post('https://lincoln.curaytor.net/twirp/curaytor.lincoln.EventService/ResetAndReindexAudienceOnAlgolia', data, {
        headers: {
          Authorization: `Bearer ${this.token_object.algolia_reset_reindex_aud_token}`,
          "Content-Type": "application/json"
        }
      })
      .then(res => {
        if ((res.status > 199 && res.status < 300) || res.message == "Endpoint request timed out") {
          this.resetUsersCrmSitesDb();
        }
      })
      .catch(error => {
        console.log(error)
        this.errorMsg = error;
        this.showError = true;
      })
    },
    updateCRM() {
      var data = {
        "master_crm": this.selectedCRM.toLowerCase(),
        "domain": this.domain
      }
      axios.post(`${process.env.VUE_APP_BASE_URL}/crm/preferences`, data, {
        headers: {
          Authorization: this.token_object.crm_sync_set_pref_token,
          "Content-Type": "application/json"
        }
      })
      .then(res => {
        if ((res.status > 199 && res.status < 300) || res.message == "Endpoint request timed out") {
          this.resetLeadDataAlgolia();
        }
      })
      .catch(error => {
        console.log(error)
        this.errorMsg = error;
        this.showError = true;
      })
    },
    initSitesTA() {
      $('.sites-TA-sync').typeahead('destroy');
      let domains = new Bloodhound({
         datumTokenizer: function(d) {
          return Bloodhound.tokenizers.whitespace(d.domain);
        },
        identify: function(obj) { return obj.domain; },
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        local: this.domain_list,
        limit: 10
      });

      function domainsWithDefaults(q, sync) {
        if (q === '') {
          sync(domains.all()); 
        }
        else {
          domains.search(q, sync);
        }
      }
      $('#sites-select-switch .typeahead').typeahead({
        hint: false,
        highlight: true,
        minLength: 0
      },
      {
        name: 'domains',
        displayKey: 'domain',
        source: domainsWithDefaults,
        limit: 10
      })
      $('#sites-select-switch').on('typeahead:selected', (e, datum) => {
        this.selectedSite = datum
        this.domain = this.selectedSite.domain;
        this.site_id = this.selectedSite.site_id;
      });
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#reminder {
  font-size: 1em;
  font-style: italic;
  color:rgb(182, 40, 40);
}

#reminder-cont {
  width: 50%;
  align-self: center;
  margin: 0% 25%;
}

.SwitchCRM {
  margin-bottom: 3em;
}
</style>
