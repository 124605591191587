<template>
  <div class="Login">
    <button v-if="!isSignedInCuraytor"
      @click.prevent="signIn()"
      class="loginBtn loginBtn--google"
    >
    <img id="google_btn_img" src="../assets/btn_google_signin_dark.png"/>
    </button>
    <div v-if="showWarning" class="error-text">
      <p>Please sign in with your Curaytor Gmail account</p>
    </div>
    <div v-if="isSignedInCuraytor">
      <button type="button" class="sign-out"><span @click.stop.prevent="signOut">Sign Out</span></button>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
const firebase = require('firebase/app');
require('firebase/auth');
require("firebase/firestore");

export default {
  name: 'Login',
  mounted() {
    this.initFirebase();
    this.checkUser();
  },
  props: {},
  data() {
    return {
      isSignedInCuraytor: null,
      token: null,
      auth_api_token: null,
      showWarning: null,
      tokenObj: null
    };
  },
  watch: {
    tokenObj: function() {
      this.$emit("childSyncInfo", this.tokenObj);
      this.$emit("syncSignedIn", this.isSignedInCuraytor)
    },
    isSignedInCuraytor: function() {
      this.$emit("syncSignedIn", this.isSignedInCuraytor)
      if (this.isSignedInCuraytor) { 
        this.getTokensFromFB();
      }
    }
  },
  methods: {
    initFirebase() {
      var firebaseConfig = {
        apiKey: process.env.VUE_APP_FB_API_KEY,
        authDomain: process.env.VUE_APP_AUTH_DOMAIN,
        databaseURL: process.env.VUE_APP_FB_DATABASE_URL,
        projectId: process.env.VUE_APP_PROJECT_ID,
        storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
        messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
        appId: process.env.VUE_APP_APP_ID,
        measurementId: process.env.VUE_APP_MEASUREMENT_ID
      };
      firebase.initializeApp(firebaseConfig);
      var db = firebase.firestore();
      this.db = db;
    },
    getTokensFromFB() {
      var tokenObj = {};
      var collection = this.db.collection("tokens").get()
      .then((collection) => {
        collection.forEach((doc)=> {
          var data = doc.data();
          tokenObj[Object.keys(data)[0]] = Object.values(data)[0];
        })
        this.tokenObj = tokenObj
      }).catch(function(error) {
        console.log("Error getting document:", error);
      });
    },
    signIn() {
      let provider = new firebase.auth.GoogleAuthProvider();
      provider.setCustomParameters({
        prompt: 'select_account'
      });
      firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
      firebase.auth().signInWithPopup(provider)
      
      .then((result) => {
        this.showWarning = false
        this.isSignedInCuraytor = true
        var token = result.credential.accessToken;
        var user = result.user;
        if (user) {
          let isCuraytorEmail = user.email.includes('curaytor.com');
          if (isCuraytorEmail) {
            this.isSignedInCuraytor = true;
          }
        }
      }).catch((error) => {
        console.log(error)
        var errorCode = error.code;
        var errorMessage = error.message;
        var email = error.email;
        var credential = error.credential;
        this.showWarning = true;
      });
    },
    checkUser() {
      firebase.auth().onAuthStateChanged(user => { 
        if (user) {
          let isCuraytorEmail = user.email.includes('curaytor.com');
          if (isCuraytorEmail) {
            this.isSignedInCuraytor = true;
          }
        }
        else {
          console.log('user not logged in')
        }
      }) 
    },
    signOut() {
      firebase.auth().signOut();
      this.isSignedInCuraytor = false;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#google_btn_img {
  margin: 0;
  padding: 0;
  border: none;
  height: 4em;
}

.loginBtn--google {
  margin: 0;
  padding: 0;
  border: none;
}

.loginBtn--google:hover,
.loginBtn--google:focus {
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
}
</style>
